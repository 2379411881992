.course-selection.cbit-dialog {
    margin: 0 auto;
    max-width: 1234px;
    border-radius: 0;
}

.course-container {
    display: flex;
    flex-wrap: wrap;    
    gap: 2rem;
}

.course-item.cbit-container {
    padding: 1rem;    
    flex: 0 0 calc(33% - 2rem);  
    /* width: auto !important;  */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.course-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
}

.course-header-container .cbit-dashboard-header {
    margin: 0;
}

.course-header-container .btn-cbit-primary {
    padding: 1rem;
}


.course-item .cbit-header a {
    color: var(--cbit-blue);
}

.course-item .cbit-header a:hover{
    text-decoration: none;
}

.course-item-actions {
    width: 100%;
    align-self: center;
}

.course-item-actions {
    display: flex;
    justify-content: space-between;
}