
.hero-container {    
    position:relative;    
    margin: 0 auto;
    max-width: 1920px;
    height: var(--cbit-full-height);        
}
.cbit-hero {
    width: 100%;
    height: 100%;
    display: flex;  
    padding: 6.25rem 0 6.25rem 8rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-repeat: no-repeat;
    object-fit: cover;
    background-color: var(--cbit-cadet-blue);  
}
.cbit-hero-1 {
    background-image: url('../../../resources/images/cbit-hero01-large.jpg');
}
.cbit-hero.mobile-hero {
    display: none;
    flex-grow: 1;
}
.cbit-hero-inner {    
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;  
    display:flex; 
}

.cbit-hero-inner-right,
.cbit-hero-inner-mobile {
  color:#fff;
  width:100%;
  display:flex;
  flex-direction:column;  
  gap:22px;
}

.cbit-hero-inner-text {
    width:41%;
    min-width:656px;    
    font-size: 4rem;
    font-weight:300;
    line-height: 72px;
}

.cbit-inner-text {
    max-width: 640px;
    line-height: 32px;
    font-size: 1.25rem;
    letter-spacing: 0;
    font-weight: 300;    
}

.btn-cbit-hero.btn-cbit-primary {  
  width: fit-content;  
  /* width: 392px; */
  padding: 1.03rem 3.2rem;
  /* height: 69px; */
  font-size: 1.5rem;
  letter-spacing: 2.4px;  
}