.content-log.cbit-container {
    border-radius: 0 !important;
    padding: 45px 40px;
    background-color: #DEE5E7;    
    width: 520px;
}
.content-log-desc {
    margin-bottom: 2.5rem;
}
.content-log-date-range {
    display: flex;
    gap: 10px;
    margin-bottom: 2rem; 
}
.content-log-date-range .cbit-label {
    margin: 0;
    display: flex;
    align-items: center;
}

.content-log-date-range .form-input {
    max-width: 176px;
    align-self: center;
}

.content-log .btn-cbit-primary {
    width: 264px;
    height: 44px;
    letter-spacing: 1.8px;
}