.admin-search-container {
    position: relative;
    margin: 7px 0 20px;
}

.admin-search-icon {
    position: absolute;
    top: 6px;
    left: 12px;    
    width: 20px;    
}

.admin-search-icon img {
    width: 100%;
}

.admin-search-container .form-input {
    padding-left: 32px;
}

.user-mana-search {
    max-width: 227px;
}
