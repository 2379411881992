
.chapter-content-container {
    padding-right: 24px !important;
}

.chapter-content-container.active-nav-item {
    background-color: #D6F2F9;
}

.chapter-content-container .sidebar-link-text {
    text-align: left;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.9px;
    color: var(--cbit-dark-blue);
    text-transform: capitalize;
}

.chapter-content-container.active-nav-item .sidebar-link-text  {
    font-weight: bold;
}
