.float-left{
    float:left;
}
.float-right{
    float:right;
}

.video-button-offset{
    margin-top:15px;
    margin-right:80px;
}
.video-text-offset{
    margin-top:15px;
    margin-left:80px;
}
.video-text-title{
    width: 100%;
    max-width: 870px;
    color:#00516B;
    font-size: 28px;
    line-height: 40px;
    text-align: left;
}
.video-text-body{
    color: #353B3C;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0;
    opacity: 1;
    width: 801px;
}
.video-player-background {
    height: calc(30vw);
    margin-bottom: 32px;
    background: #353B3C;
}

.ImageStandIn {
    width: 100%;
    height: 400px;
    background: lightskyblue;
}
.buffer-top-10 {
    margin-top: 10px;
}
.disabled-color {
    color: #979797;
}

.video-content-panel-header {
    margin: 20px 32px 16px;
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.quiz-content-panel,
.combo-lesson-quiz-content-panel
{
    margin-top:65px;
    margin-left:80px;
    margin-right:80px;
}

.quiz-title,
.combo-lesson-quiz-title {
    color: #00516B;
    font-family: 'Open Sans';
    font-size: 32px;
    margin-bottom:32px;
    letter-spacing: 0;
}
.quiz-subHeader {
    color: var(--cbit-bondi-blue);
    font-family: 'Open Sans';
    font-size: 24px;
    letter-spacing: 0;
    margin-bottom: 12px;
}
.quiz-instruction-text {
    font-family: 'Open Sans';
    font-size: 16px;
    letter-spacing: 0;
    color: #353B3C;
    margin-bottom: 32px;
}
.quiz-question-text {
    font-family: 'Open Sans';
    font-size: 16px;
    letter-spacing: 0;
    color: #353B3C;
    margin-bottom: 32px;
}

.quiz-question-seperation {
    margin-left: 32px;
    margin-bottom: 16px;
}

.quiz-question-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.quiz-question-container input {
    margin-top: 5px;
}

.quiz-question-title-seperation {
    display: flex;
    margin-bottom: 16px;

}
.quiz-question-left-margin{
    margin-left:16px;
}
.quiz-footer,
.combo-lesson-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.quiz-footer-content,
.combo-lesson-footer-content {
    margin-bottom: 32px;
}
.quiz-footer-left-content {
    position: relative;
    left: calc(50% - 190px);
    margin-bottom: 32px;
}
.quiz-footer-right-content,
.combo-lesson-footer-right-content {
    margin-bottom: 32px;
}
.CorrectRadioButtonText {
    color: var(--cbit-pantone-green);
    font-weight: 500;
}
.inCorrectRadioButtonText {
    color: var(--cbit-coral-red);
    font-weight: 500;
}

i.bi.bi-x-lg.radioDesignationAddjustment {
    -webkit-text-stroke: 1px var(--cbit-coral-red);
}

i.bi.bi-check-lg.radioDesignationAddjustment {
    -webkit-text-stroke: 1px var(--cbit-pantone-green);
}

.radioButtonAnswerAdjustment {
    margin-left: -35px;
}
.radioDesignationAddjustment {
    margin-right: 19px;
}
.radioTextAdjustment {
    margin-left: 15px;
}
.radioTextAdjustment.quiz-label {
    cursor: pointer;
    margin-bottom: 0;
}

.textLessonImage {
    width: 100%;
    margin-bottom:32px;
}